import * as Form from '@radix-ui/react-form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select"

export type SelectInputProps = {
  id?: string;
  name?: string;
  value?: string;
  label?: string;
  options: Array<{ id: string; name: string }>;
  helpText?: string;
  errors?: string[];
  placeholder?: string;
  onValueChange?: (value: string) => void;
};

export default function SelectInput({ 
  id, 
  name, 
  value, 
  label, 
  options, 
  helpText, 
  errors, 
  placeholder,
  onValueChange,
}: SelectInputProps) {
  return (
    <Form.Field name={name}>
      {label && (
        <Form.Label className="text-sm font-medium mb-2 block">
          {label}
        </Form.Label>
      )}

      <Select
        name={name}
        value={value}
        onValueChange={onValueChange}
      >
        <SelectTrigger className="w-full" aria-invalid={!!errors}>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {options?.map((option) => (
            <SelectItem key={option.id} value={option.id}>
              {option.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      {errors && errors.length > 0 && (
        <p className="text-sm text-destructive mt-2">{errors[0]}</p>
      )}
      {helpText && (
        <p className="text-sm text-muted-foreground mt-1">{helpText}</p>
      )}
    </Form.Field>
  )
}
