import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "~/lib/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 relative isolate cursor-default",
  {
    variants: {
      variant: {
        default: 
          "bg-primary text-primary-foreground before:absolute before:inset-0 before:-z-10 before:rounded-[calc(theme(borderRadius.md)-1px)] before:bg-primary before:shadow-xs dark:before:hidden after:absolute after:inset-0 after:-z-10 after:rounded-[calc(theme(borderRadius.md)-1px)] after:transition-all hover:after:bg-white/15 active:after:bg-black/20 active:shadow-[inset_0_3px_5px_rgba(0,0,0,0.3)] dark:after:-inset-px dark:after:rounded-md",
        destructive:
          "bg-destructive text-destructive-foreground before:absolute before:inset-0 before:-z-10 before:rounded-[calc(theme(borderRadius.md)-1px)] before:bg-destructive before:shadow-xs dark:before:hidden after:absolute after:inset-0 after:-z-10 after:rounded-[calc(theme(borderRadius.md)-1px)] after:transition-all hover:after:bg-white/15 active:after:bg-black/20 active:shadow-[inset_0_3px_5px_rgba(0,0,0,0.3)] dark:after:-inset-px dark:after:rounded-md text-white",
        outline:
          "border border-input bg-background before:absolute before:inset-0 before:-z-10 before:rounded-[calc(theme(borderRadius.md)-1px)] before:bg-background before:shadow-xs dark:before:hidden after:absolute after:inset-0 after:-z-10 after:rounded-[calc(theme(borderRadius.md)-1px)] after:transition-all hover:after:bg-black/10 active:after:bg-black/15 active:shadow-[inset_0_3px_5px_rgba(0,0,0,0.2)] dark:after:-inset-px dark:after:rounded-md",
        secondary:
          "bg-secondary text-secondary-foreground before:absolute before:inset-0 before:-z-10 before:rounded-[calc(theme(borderRadius.md)-1px)] before:bg-secondary before:shadow-xs dark:before:hidden after:absolute after:inset-0 after:-z-10 after:rounded-[calc(theme(borderRadius.md)-1px)] after:transition-all hover:after:bg-white/15 active:after:bg-black/20 active:shadow-[inset_0_3px_5px_rgba(0,0,0,0.3)] dark:after:-inset-px dark:after:rounded-md",
        ghost: 
          "hover:text-accent-foreground after:absolute after:inset-0 after:-z-10 after:rounded-[calc(theme(borderRadius.md)-1px)] after:transition-all hover:after:bg-black/10 active:after:bg-black/15 active:shadow-[inset_0_3px_5px_rgba(0,0,0,0.2)]",
        link: 
          "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-8 px-3 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
