import React from 'react';
import { Handler } from '~/types';
import { Input } from "~/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select";
import { Button } from "~/components/ui/button";
import { X } from "lucide-react";

interface HandlerFormProps {
  handler: Handler;
  onChange: (handler: Handler) => void;
  onDelete: () => void;
  errors?: Record<string, [string]>;
}

const LISTEN_OPTIONS = [
  { id: 'attachment', name: 'Element Attachment' },
  { id: 'custom', name: 'Custom Event' }
];

const QUALIFIER_OPTIONS = [
  { id: 'findButtonByText', name: 'Button with Text' },
  // Add more qualifiers as needed
];

const EVENT_OPTIONS = [
  { id: 'click', name: 'Click' },
  // Add more events as needed
];

export default function HandlerForm({ handler, onChange, onDelete, errors }: HandlerFormProps) {
  const isCustomEvent = handler.listen === 'custom';

  return (
    <div className="bg-card rounded-lg border p-4 space-y-4">
      <div className="flex items-center justify-between gap-3 flex-1">
        <div className="flex items-center gap-3 flex-1">
          <Select
            value={handler.listen}
            onValueChange={(value) => onChange({ ...handler, listen: value as 'attachment' | 'custom' })}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select type" />
            </SelectTrigger>
            <SelectContent>
              {LISTEN_OPTIONS.map(option => (
                <SelectItem key={option.id} value={option.id}>
                  {option.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          {isCustomEvent ? (
            <div className="flex-1 space-y-2">
              <Input
                value={handler.event_name}
                onChange={(e) => onChange({ ...handler, event_name: e.target.value })}
                placeholder="my.custom.event"
                aria-invalid={!!errors?.event_name}
              />
              {errors?.event_name && (
                <p className="text-sm text-destructive">{errors.event_name[0]}</p>
              )}
            </div>
          ) : (
            <div className="flex gap-3 flex-1">
              <Select
                value={handler.qualifier || ''}
                onValueChange={(value) => onChange({ ...handler, qualifier: value })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select qualifier" />
                </SelectTrigger>
                <SelectContent>
                  {QUALIFIER_OPTIONS.map(option => (
                    <SelectItem key={option.id} value={option.id}>
                      {option.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <Select
                value={handler.event_name}
                onValueChange={(value) => onChange({ ...handler, event_name: value })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select event" />
                </SelectTrigger>
                <SelectContent>
                  {EVENT_OPTIONS.map(option => (
                    <SelectItem key={option.id} value={option.id}>
                      {option.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              {handler.qualifier === 'findButtonByText' && (
                <div className="flex-1 space-y-2">
                  <Input
                    value={handler.props?.text || ''}
                    onChange={(e) => onChange({ 
                      ...handler, 
                      props: { ...handler.props, text: e.target.value } 
                    })}
                    placeholder="Button text..."
                    aria-invalid={!!errors?.props?.text}
                  />
                  {errors?.props?.text && (
                    <p className="text-sm text-destructive">{errors.props.text[0]}</p>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <Button
          onClick={(evt) => {
            evt.preventDefault(); 
            onDelete();
          }}
          variant="ghost"
          size="icon"
          className="text-muted-foreground hover:text-destructive"
        >
          <X className="h-4 w-4" />
        </Button>
      </div>

      {/* Show errors in a compact way */}
      {errors && Object.entries(errors).map(([key, [error]]) => {
        // Don't show errors that are already shown in TextInput components
        if (key === 'event_name' || key === 'props.text') return null;
        return (
          <p key={key} className="text-xs text-destructive">
            {error}
          </p>
        );
      })}
    </div>
  );
} 