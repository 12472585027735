import { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuLink,
} from "~/components/ui/navigation-menu";

interface OrganizationLayoutProps {
  children: ReactNode;
}

const navigationItems = [
  { href: "/settings/organization", label: "Organization" },
  { href: "/billing/charges", label: "Charges" },
  { href: "/mgmt/usage_events", label: "Usage Events" },
  { href: "/catalog/metrics", label: "Metrics" },
  { href: "/catalog/feature_sets", label: "Feature Sets" },
  { href: "/mgmt/attributions", label: "Attributions" },
  { href: "/mgmt/agents", label: "Agents" },
];

export default function OrganizationLayout({ children }: OrganizationLayoutProps) {
  const location = useLocation();

  return (
    <div className="min-h-screen">
      <div className="border-b">
        <div className="px-10 py-4">
          <NavigationMenu>
            <NavigationMenuList>
              {navigationItems.map((item) => (
                <NavigationMenuItem key={item.href}>
                  <NavigationMenuLink
                    href={item.href}
                    active={location.pathname === item.href}
                  >
                    {item.label}
                  </NavigationMenuLink>
                </NavigationMenuItem>
              ))}
            </NavigationMenuList>
          </NavigationMenu>
        </div>
      </div>
      <div className="p-10">
        {children}
      </div>
    </div>
  );
}