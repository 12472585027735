
import {NavLink, Outlet, useLocation, useOutlet} from "react-router-dom";
import React, {ContextType, createContext, useContext, useEffect, useState} from "react";


export default function CatalogLayout() {

  const [navigationContent, setNavigationContent] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setNavigationContent(null);
  }, []);

  return (
    <Outlet context={{ setNavigationContent }} />
  )
}
