import React from "react";
import {Link} from "react-router-dom";
import { cn } from "~/lib/utils";

export type PageHeaderProps = {
  children: React.ReactNode;
  navigation?: React.ReactNode;
}

function PageHeader({ children, navigation, className }: PageHeaderProps) {
  return (
    <div className={cn("flex justify-start gap-2", className)}>
      {navigation}
      <div className="flex items-center  justify-between w-full">
        {children}
      </div>
    </div>
  )
}

export default PageHeader;
