import { useState } from "react";
import { ViewStage, ViewSection, TextBlock, ImageBlock, CheckboxGroupBlock } from "~/types/view";
import { 
  PlusIcon, 
  Trash2Icon, 
  GripVerticalIcon, 
  ChevronUpIcon, 
  ChevronDownIcon, 
  Settings2Icon 
} from "lucide-react";
import { Card } from "~/components/ui/card";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Textarea } from "~/components/ui/textarea";
import { Label } from "~/components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/components/ui/tabs";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "~/components/ui/select";
import { Separator } from "~/components/ui/separator";
import { Switch } from "~/components/ui/switch";
import { Checkbox } from "~/components/ui/checkbox";

type ViewEditorProps = {
  value: ViewStage[];
  onChange: (stages: ViewStage[]) => void;
};

type BlockEditorProps = {
  block: TextBlock | ImageBlock | CheckboxGroupBlock;
  onChange: (block: TextBlock | ImageBlock | CheckboxGroupBlock) => void;
  onDelete: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  isFirst?: boolean;
  isLast?: boolean;
};

const BLOCK_TYPES = [
  { value: 'h1', label: 'Heading 1' },
  { value: 'h2', label: 'Heading 2' },
  { value: 'h3', label: 'Heading 3' },
  { value: 'p', label: 'Paragraph' },
  { value: 'button', label: 'Button' },
  { value: 'dl-group', label: 'Description List' },
  { value: 'list', label: 'List' },
  { value: 'img', label: 'Image' },
  { value: 'checkbox-group', label: 'Checkbox Group' },
  { value: 'divider', label: 'Divider' },
  { value: 'spacer', label: 'Spacer' },
];

const COMMON_STYLES = [
  { key: 'backgroundColor', label: 'Background Color' },
  { key: 'color', label: 'Text Color' },
  { key: 'padding', label: 'Padding' },
  { key: 'margin', label: 'Margin' },
  { key: 'borderRadius', label: 'Border Radius' },
  { key: 'fontSize', label: 'Font Size' },
  { key: 'fontWeight', label: 'Font Weight' },
  { key: 'textAlign', label: 'Text Align' },
  { key: 'width', label: 'Width' },
  { key: 'height', label: 'Height' },
];

function StyleEditor({ 
  styles, 
  onChange 
}: { 
  styles: Record<string, string>, 
  onChange: (styles: Record<string, string>) => void 
}) {
  const [newStyleKey, setNewStyleKey] = useState('');
  const [showStyleSelector, setShowStyleSelector] = useState(false);

  const addStyle = (key: string) => {
    onChange({ ...styles, [key]: '' });
    setShowStyleSelector(false);
  };

  const removeStyle = (key: string) => {
    const newStyles = { ...styles };
    delete newStyles[key];
    onChange(newStyles);
  };

  return (
    <div className="space-y-2 mt-2 pt-2 border-t border-gray-100">
      <div className="flex items-center justify-between">
        <h4 className="text-xs font-medium text-gray-500">Styles</h4>
        {!showStyleSelector && (
          <Button 
            size="sm" 
            variant="ghost" 
            onClick={() => setShowStyleSelector(true)}
            className="h-6 px-2 text-xs"
          >
            <PlusIcon className="h-3 w-3 mr-1" />
            Add
          </Button>
        )}
      </div>

      {showStyleSelector && (
        <Card className="mb-2 p-2">
          <div className="space-y-2">
            <h4 className="text-xs font-medium">Common Styles</h4>
            <div className="grid grid-cols-2 gap-1">
              {COMMON_STYLES.map(style => (
                <Button
                  key={style.key}
                  size="sm"
                  variant="ghost"
                  onClick={() => addStyle(style.key)}
                  disabled={style.key in styles}
                  className="h-6 text-xs justify-start"
                >
                  {style.label}
                </Button>
              ))}
            </div>
            <div className="flex gap-1 mt-2">
              <Input
                type="text"
                placeholder="Custom style..."
                value={newStyleKey}
                onChange={(e) => setNewStyleKey(e.target.value)}
                className="h-7 text-xs"
              />
              <Button 
                size="sm"
                variant="ghost"
                onClick={() => {
                  if (newStyleKey) {
                    addStyle(newStyleKey);
                    setNewStyleKey('');
                  }
                }}
                disabled={!newStyleKey}
                className="h-7 px-2 text-xs whitespace-nowrap"
              >
                Add
              </Button>
            </div>
            <Button 
              size="sm"
              variant="ghost"
              onClick={() => setShowStyleSelector(false)}
              className="h-6 px-2 text-xs w-full"
            >
              Cancel
            </Button>
          </div>
        </Card>
      )}

      {Object.entries(styles).map(([key, value]) => (
        <div key={key} className="flex gap-1 items-center">
          <div className="flex-1">
            <Label className="text-xs text-gray-400">{key}</Label>
            <div className="flex gap-1">
              <Input
                type="text"
                value={value}
                onChange={(e) => onChange({ ...styles, [key]: e.target.value })}
                placeholder={`Enter ${key}...`}
                className="h-7 text-xs"
              />
              <Button 
                size="sm" 
                variant="ghost" 
                className="h-7 w-7 p-0 text-destructive hover:text-destructive"
                onClick={() => removeStyle(key)}
              >
                <Trash2Icon className="h-3 w-3" />
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

type CheckboxGroupEditorProps = {
  block: CheckboxGroupBlock;
  onChange: (block: CheckboxGroupBlock) => void;
};

function CheckboxGroupEditor({ block, onChange }: CheckboxGroupEditorProps) {
  const handleChange = (field: string, value: any) => {
    onChange({
      ...block,
      props: {
        ...block.props,
        [field]: value
      }
    });
  };

  const handleAddOption = () => {
    const options = block.props?.options || [];
    onChange({
      ...block,
      props: {
        ...block.props,
        options: [
          ...options,
          {
            value: `option-${options.length + 1}`,
            label: `Option ${options.length + 1}`,
            caption: '',
            disabled: false
          }
        ]
      }
    });
  };

  const handleUpdateOption = (index: number, field: string, value: any) => {
    const options = [...(block.props?.options || [])];
    options[index] = {
      ...options[index],
      [field]: value
    };
    onChange({
      ...block,
      props: {
        ...block.props,
        options
      }
    });
  };

  const handleRemoveOption = (index: number) => {
    const options = [...(block.props?.options || [])];
    options.splice(index, 1);
    onChange({
      ...block,
      props: {
        ...block.props,
        options
      }
    });
  };

  return (
    <div className="space-y-4">
      {/* Group Settings */}
      <div className="space-y-2 border-b pb-4">
        <h4 className="text-xs font-medium mb-2">Group Settings</h4>
        <div className="grid grid-cols-2 gap-2">
          <div className="space-y-1.5">
            <Label className="text-xs">ID:</Label>
            <Input
              type="text"
              value={block.props?.id || ''}
              onChange={(e) => handleChange('id', e.target.value)}
              placeholder="Enter ID..."
              className="h-7 text-xs"
            />
          </div>
          <div className="space-y-1.5">
            <Label className="text-xs">Name:</Label>
            <Input
              type="text"
              value={block.props?.name || ''}
              onChange={(e) => handleChange('name', e.target.value)}
              placeholder="Enter name..."
              className="h-7 text-xs"
            />
          </div>
        </div>
        <div className="flex items-center gap-4 mt-2">
          <div className="flex items-center gap-2">
            <Switch
              checked={block.props?.rules?.required || false}
              onCheckedChange={(checked) => handleChange('rules', { required: checked })}
              id={`${block.id}-required`}
            />
            <Label htmlFor={`${block.id}-required`} className="text-xs cursor-pointer">
              Required
            </Label>
          </div>
          <div className="flex items-center gap-2">
            <Switch
              checked={block.props?.disabled || false}
              onCheckedChange={(checked) => handleChange('disabled', checked)}
              id={`${block.id}-disabled`}
            />
            <Label htmlFor={`${block.id}-disabled`} className="text-xs cursor-pointer">
              Disabled
            </Label>
          </div>
        </div>
      </div>

      {/* Options List */}
      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <h4 className="text-xs font-medium">Options</h4>
          <Button
            size="sm"
            variant="outline"
            onClick={handleAddOption}
            className="h-7 text-xs"
          >
            <PlusIcon className="h-3 w-3 mr-1" />
            Add Option
          </Button>
        </div>
        
        {/* Default Value Selection */}
        <div className="border rounded p-2 bg-gray-50">
          <Label className="text-xs mb-1 block">Default Selected Values:</Label>
          <div className="space-y-1">
            {(block.props?.options || []).map((option, index) => (
              <div key={`default-${index}`} className="flex items-center gap-2">
                <Checkbox
                  id={`default-${block.id}-${index}`}
                  checked={(block.props?.defaultValue || []).includes(option.value)}
                  onCheckedChange={(checked) => {
                    const currentDefaults = block.props?.defaultValue || [];
                    const newDefaults = checked 
                      ? [...currentDefaults, option.value]
                      : currentDefaults.filter((v: string) => v !== option.value);
                    handleChange('defaultValue', newDefaults);
                  }}
                />
                <Label 
                  htmlFor={`default-${block.id}-${index}`}
                  className="text-xs cursor-pointer"
                >
                  {option.label}
                </Label>
              </div>
            ))}
          </div>
        </div>

        <div className="space-y-2 mt-4">
          {(block.props?.options || []).map((option, index) => (
            <Card key={index} className="p-2">
              <div className="space-y-2">
                <div className="flex items-center justify-between mb-2">
                  <Label className="text-xs font-medium">Option {index + 1}</Label>
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => handleRemoveOption(index)}
                    className="h-7 w-7 p-0 text-destructive hover:text-destructive"
                  >
                    <Trash2Icon className="h-3 w-3" />
                  </Button>
                </div>
                
                <div className="grid grid-cols-2 gap-2">
                  <div className="space-y-1">
                    <Label className="text-xs">Value:</Label>
                    <Input
                      value={option.value}
                      onChange={(e) => handleUpdateOption(index, 'value', e.target.value)}
                      placeholder="Value..."
                      className="h-7 text-xs"
                    />
                  </div>
                  <div className="space-y-1">
                    <Label className="text-xs">Label:</Label>
                    <Input
                      value={option.label}
                      onChange={(e) => handleUpdateOption(index, 'label', e.target.value)}
                      placeholder="Label..."
                      className="h-7 text-xs"
                    />
                  </div>
                </div>
                
                <div className="space-y-1">
                  <Label className="text-xs">Caption (optional):</Label>
                  <Input
                    value={option.caption || ''}
                    onChange={(e) => handleUpdateOption(index, 'caption', e.target.value)}
                    placeholder="Caption..."
                    className="h-7 text-xs"
                  />
                </div>
                
                <div className="flex items-center gap-2 pt-1">
                  <Switch
                    checked={option.disabled || false}
                    onCheckedChange={(checked) => handleUpdateOption(index, 'disabled', checked)}
                    id={`${block.id}-option-${index}-disabled`}
                  />
                  <Label 
                    htmlFor={`${block.id}-option-${index}-disabled`}
                    className="text-xs cursor-pointer"
                  >
                    Disabled
                  </Label>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}

function BlockEditor({ block, onChange, onDelete, onMoveUp, onMoveDown, isFirst, isLast }: BlockEditorProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [showStyles, setShowStyles] = useState(false);

  const content = block.type === 'img' 
    ? block.props?.src || '' 
    : (block as TextBlock).text?.[0]?.props?.content || '';
  const type = block.type;
  const styles = block.style || {};

  const generateId = () => {
    onChange({
      ...block,
      id: crypto.randomUUID()
    });
  };

  const handleCheckboxGroupChange = (field: string, value: any) => {
    onChange({
      ...block,
      props: {
        ...block.props,
        [field]: value
      }
    });
  };

  const handleAddOption = () => {
    const options = block.props?.options || [];
    onChange({
      ...block,
      props: {
        ...block.props,
        options: [
          ...options,
          {
            value: `option-${options.length + 1}`,
            label: `Option ${options.length + 1}`,
            caption: '',
            disabled: false
          }
        ]
      }
    });
  };

  const handleUpdateOption = (index: number, field: string, value: any) => {
    const options = [...(block.props?.options || [])];
    options[index] = {
      ...options[index],
      [field]: value
    };
    onChange({
      ...block,
      props: {
        ...block.props,
        options
      }
    });
  };

  const handleRemoveOption = (index: number) => {
    const options = [...(block.props?.options || [])];
    options.splice(index, 1);
    onChange({
      ...block,
      props: {
        ...block.props,
        options
      }
    });
  };

  return (
    <Card className="mb-2 overflow-hidden !py-0">
      <div className="p-2 space-y-2">
        {/* Header with type and controls */}
        <div className="flex items-center justify-between border-b pb-1 mb-1">
          <div className="flex items-center gap-1">
            <div className="flex flex-col">
              <Button 
                size="sm" 
                variant="ghost" 
                disabled={isFirst}
                onClick={onMoveUp}
                className="h-6 w-6 p-0"
              >
                <ChevronUpIcon className="h-3 w-3" />
              </Button>
              <Button 
                size="sm" 
                variant="ghost" 
                disabled={isLast}
                onClick={onMoveDown}
                className="h-6 w-6 p-0"
              >
                <ChevronDownIcon className="h-3 w-3" />
              </Button>
            </div>
            <span className="text-xs font-medium ml-1">{BLOCK_TYPES.find(t => t.value === type)?.label || type}</span>
          </div>
          
          <div className="flex items-center gap-1">
            <Button 
              size="sm" 
              variant="ghost" 
              onClick={() => setIsEditing(!isEditing)}
              className="h-6 px-1 text-xs"
            >
              {isEditing ? 'Done' : 'Edit'}
            </Button>
            <Button 
              size="sm" 
              variant="ghost" 
              onClick={() => setShowStyles(!showStyles)}
              className="h-6 px-1 text-xs"
            >
              <Settings2Icon className="h-3 w-3 mr-1" />
              Styles
            </Button>
            <Button 
              size="sm" 
              variant="ghost" 
              className="h-6 px-1 text-xs text-destructive hover:text-destructive"
              onClick={onDelete}
            >
              <Trash2Icon className="h-3 w-3" />
            </Button>
          </div>
        </div>

        {/* ID information */}
        <div className="flex items-center text-xs text-gray-400 mb-1">
          <span className="font-mono">ID: {block.id || 'None'}</span>
          {!block.id && (
            <Button 
              size="sm" 
              variant="ghost" 
              className="h-5 px-1 text-xs ml-1"
              onClick={generateId}
            >
              Generate
            </Button>
          )}
        </div>

        {/* Content area */}
        {isEditing ? (
          <div className="space-y-1">
            {type === 'img' ? (
              <Input
                type="text"
                value={content}
                onChange={(e) => {
                  onChange({
                    ...block,
                    type: 'img',
                    props: {
                      ...block.props,
                      src: e.target.value
                    }
                  } as ImageBlock);
                }}
                placeholder="Enter image URL..."
                className="text-sm"
              />
            ) : type === 'checkbox-group' ? (
              <CheckboxGroupEditor 
                block={block as CheckboxGroupBlock} 
                onChange={onChange as (block: CheckboxGroupBlock) => void}
              />
            ) : (
              <>
                <Textarea 
                  value={content}
                  onChange={(e) => {
                    onChange({
                      ...block,
                      text: [{
                        props: { content: e.target.value },
                        object: 'text',
                        annotations: (block as TextBlock).text?.[0]?.annotations || { bold: false }
                      }]
                    } as TextBlock);
                  }}
                  placeholder="Enter content..."
                  className="min-h-[80px] text-sm"
                />
                <div className="text-xs text-gray-400">
                  Variables: {'{{data.product.name}}'}, {'{{data.price}}'}, {'{{data.description}}'}
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="prose prose-sm max-w-none py-1" style={styles}>
            {type === 'h1' && <h1 className="text-xl font-bold m-0">{content}</h1>}
            {type === 'h2' && <h2 className="text-lg font-bold m-0">{content}</h2>}
            {type === 'h3' && <h3 className="text-base font-bold m-0">{content}</h3>}
            {type === 'p' && <p className="m-0">{content}</p>}
            {type === 'button' && (
              <button className="px-3 py-1 bg-blue-500 text-white rounded text-sm">
                {content}
              </button>
            )}
            {type === 'divider' && <Separator className="my-1" />}
            {type === 'spacer' && <div className="h-4" />}
            {type === 'img' && (
              <div className="border border-dashed border-gray-300 rounded p-2 text-center">
                {content ? (
                  <img 
                    src={content} 
                    alt="" 
                    className="max-w-full h-auto"
                    loading="lazy"
                    decoding="async"
                  />
                ) : (
                  <div className="text-gray-500 text-xs">Image URL not set</div>
                )}
              </div>
            )}
            {type === 'checkbox-group' && (
              <div className="space-y-2">
                {block.props?.options?.map((option: any, index: number) => (
                  <div key={index} className="flex items-start gap-2">
                    <Checkbox
                      id={`${block.props?.id || 'checkbox'}-${index}`}
                      disabled={option.disabled || block.props?.disabled}
                      className="mt-1"
                    />
                    <div className="grid gap-1.5 leading-none">
                      <label
                        htmlFor={`${block.props?.id || 'checkbox'}-${index}`}
                        className={`text-sm font-medium leading-none ${option.disabled ? 'text-gray-400' : ''}`}
                      >
                        {option.label}
                      </label>
                      {option.caption && (
                        <p className={`text-xs ${option.disabled ? 'text-gray-400' : 'text-gray-500'}`}>
                          {option.caption}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
                {block.props?.rules?.required && (
                  <p className="text-xs text-red-500">* Required</p>
                )}
              </div>
            )}
          </div>
        )}

        {/* Styles section */}
        {showStyles && (
          <StyleEditor
            styles={styles}
            onChange={(newStyles) => onChange({ ...block, style: newStyles })}
          />
        )}
      </div>
    </Card>
  );
}

function NewBlockSelector({ onSelect }: { onSelect: (type: string) => void }) {
  return (
    <Card className="mb-2 p-2 !py-0">
      <div className="space-y-2">
        <h4 className="text-xs font-medium">Select Block Type</h4>
        <div className="grid grid-cols-3 gap-1">
          {BLOCK_TYPES.map(type => (
            <Button
              key={type.value}
              size="sm"
              variant="ghost"
              onClick={() => onSelect(type.value)}
              className="h-7 text-xs justify-start"
            >
              {type.label}
            </Button>
          ))}
        </div>
      </div>
    </Card>
  );
}

function SectionEditor({ section, onChange }: { section: ViewSection, onChange: (section: ViewSection) => void }) {
  const [showingNewBlockSelector, setShowingNewBlockSelector] = useState(false);

  const addBlock = (type: string) => {
    const newBlock = {
      id: crypto.randomUUID(),
      type,
      object: 'block' as const,
      ...(type === 'img' 
        ? { 
            props: { src: '' },
            style: {}
          } 
        : type === 'checkbox-group'
        ? {
            props: {
              options: [],
              disabled: false,
              rules: { required: false }
            },
            style: {}
          }
        : { 
            text: [{
              props: { content: 'New block' },
              object: 'text' as const,
              annotations: { bold: false }
            }]
          }
      )
    };

    onChange({
      ...section,
      blocks: [...section.blocks, newBlock]
    });
    setShowingNewBlockSelector(false);
  };

  const updateBlock = (index: number, block: TextBlock | ImageBlock | CheckboxGroupBlock) => {
    const newBlocks = [...section.blocks];
    newBlocks[index] = block;
    onChange({ ...section, blocks: newBlocks });
  };

  const deleteBlock = (index: number) => {
    const newBlocks = [...section.blocks];
    newBlocks.splice(index, 1);
    onChange({ ...section, blocks: newBlocks });
  };

  const moveBlock = (fromIndex: number, toIndex: number) => {
    const newBlocks = [...section.blocks];
    const [movedBlock] = newBlocks.splice(fromIndex, 1);
    newBlocks.splice(toIndex, 0, movedBlock);
    onChange({ ...section, blocks: newBlocks });
  };

  const generateMissingIds = () => {
    const newBlocks = section.blocks.map(block => {
      if ('id' in block && !block.id) {
        return {
          ...block,
          id: crypto.randomUUID()
        };
      }
      return block;
    });
    onChange({ ...section, blocks: newBlocks });
  };

  // Check if any blocks are missing IDs
  const hasMissingIds = section.blocks.some(block => 'id' in block && !block.id);

  return (
    <div className="space-y-2">
      <div className="grid grid-cols-5 gap-3">
        <div className="col-span-3 space-y-2">
          <div className="flex justify-between items-center mb-1">
            <span className="text-xs text-gray-500">{section.blocks.length} blocks</span>
            
            <div className="flex gap-1">
              {hasMissingIds && (
                <Button 
                  size="sm" 
                  variant="ghost" 
                  onClick={generateMissingIds}
                  className="h-6 px-2 text-xs"
                >
                  Generate IDs
                </Button>
              )}
              
              {!showingNewBlockSelector && (
                <Button 
                  onClick={() => setShowingNewBlockSelector(true)} 
                  variant="ghost"
                  size="sm"
                  className="h-6 px-2 text-xs"
                >
                  <PlusIcon className="h-3 w-3 mr-1" />
                  Add Block
                </Button>
              )}
            </div>
          </div>
          
          {showingNewBlockSelector && (
            <NewBlockSelector onSelect={addBlock} />
          )}
          
          {section.blocks.map((block, index) => {
            // Check if it's a valid block type that we can edit
            if (('text' in block && block.object === 'block') || 
                (block.type === 'img' && block.object === 'block' && 'props' in block && typeof block.props?.src === 'string') ||
                (block.type === 'checkbox-group' && block.object === 'block')) {
              return (
                <BlockEditor
                  key={block.id || index}
                  block={block as TextBlock | ImageBlock | CheckboxGroupBlock}
                  onChange={(updatedBlock) => updateBlock(index, updatedBlock)}
                  onDelete={() => deleteBlock(index)}
                  onMoveUp={() => moveBlock(index, index - 1)}
                  onMoveDown={() => moveBlock(index, index + 1)}
                  isFirst={index === 0}
                  isLast={index === section.blocks.length - 1}
                />
              );
            }
            return null;
          })}
        </div>

        <div className="col-span-2">
          <Card className="p-2 ">
            <div className="space-y-1">
              <h4 className="text-xs font-medium text-gray-500">Section JSON</h4>
              <pre className="text-xs bg-gray-50 p-2 rounded overflow-auto max-h-[300px] border text-gray-700">
                {JSON.stringify(section, null, 2)}
              </pre>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default function ViewEditor({ value, onChange }: ViewEditorProps) {
  const [activeStage, setActiveStage] = useState(0);
  const stage = value[activeStage];

  const updateSection = (sectionKey: keyof ViewStage['view'], section: ViewSection) => {
    const newStages = [...value];
    newStages[activeStage] = {
      ...stage,
      view: {
        ...stage.view,
        [sectionKey]: section
      }
    };
    onChange(newStages);
  };

  // If there are no stages, show a helpful message
  if (!value.length) {
    return (
      <Card className="p-4 text-center">
        <div className="space-y-2">
          <h3 className="text-base font-medium">No View Configuration</h3>
          <p className="text-sm text-gray-500">
            There are no stages in the view configuration. Use the "Use Default Template" button above to add a default template.
          </p>
        </div>
      </Card>
    );
  }

  if (!stage) return null;

  return (
    <div className="space-y-4">
      <div className="flex gap-1 mb-2">
        {value.map((stage, index) => (
          <Button
            key={index}
            variant={activeStage === index ? 'default' : 'outline'}
            onClick={() => setActiveStage(index)}
            size="sm"
            className="h-7 px-2 text-xs"
          >
            Stage {index + 1}
          </Button>
        ))}
      </div>

      <Card className="p-2 mb-3">
        <div className="flex items-center justify-between">
          <div>
            <h4 className="text-xs font-medium text-gray-500">Stage Provides</h4>
            <div className="text-xs text-gray-600">
              {stage.provides?.join(', ') || 'No provides defined'}
            </div>
          </div>
          <div className="text-xs text-gray-500">
            Stage {activeStage + 1} of {value.length}
          </div>
        </div>
      </Card>

      <div className="space-y-4 max-w-[1400px]">
        {Object.entries(stage.view).map(([key, section]) => (
          <Card key={key} className="overflow-hidden py-0">
            <div className="border-b px-3 py-2 bg-gray-50 flex justify-between items-center">
              <h3 className="text-sm font-medium">
                {key.charAt(0).toUpperCase() + key.slice(1)} Section
              </h3>
              <span className="text-xs text-gray-500">
                {section.blocks.length} blocks
              </span>
            </div>
            <div className="p-3">
              <SectionEditor
                section={section}
                onChange={(updatedSection) => updateSection(key as keyof ViewStage['view'], updatedSection)}
              />
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
} 