import JSONEditor from "~/components/JSONEditor";
import { useState, useEffect } from "react";
import { cn } from "~/lib/utils";

export type JSONInputProps = {
  value: object | Array<any>;
  label?: string;
  id?: string;
  onChange?: (value: object | Array<any>) => void;
  errors?: string[];
  rows?: number;
}

export default function JSONInput({ value, label, id, onChange, errors, rows = 10 }: JSONInputProps ) {
  const [internalValue, setInternalValue] = useState("");
  const [isValidJSON, setIsValidJSON] = useState(true);

  useEffect(() => {
    setInternalValue(JSON.stringify(value, null, 2));
  }, [value]);

  function handleChange(newValue: string) {
    setInternalValue(newValue);
  }

  function handleBlur() {
    try {
      const parsedJSON = JSON.parse(internalValue);
      setIsValidJSON(true);
      onChange?.(parsedJSON);
    } catch (error) {
      setIsValidJSON(false);
    }
  }

  return (
    <div className="space-y-2">
      {label && (
        <div className="flex items-center gap-2">
          <label 
            htmlFor={id} 
            className={cn(
              "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            )}
          >
            {label}
          </label>
          {!isValidJSON && (
            <span className="text-yellow-600 text-xs">* Invalid JSON</span>
          )}
        </div>
      )}

      <JSONEditor
        jsonInput={internalValue}
        setJsonInput={handleChange}
        onBlur={handleBlur}
        rows={rows}
      />

      {errors?.length > 0 && (
        <p className="text-sm text-destructive">{errors[0]}</p>
      )}
    </div>
  );
}
